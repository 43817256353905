import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
// import inputValidation from '../constants/inputValidaation';
//import "./css/progressSmall.css"
import "./css/progress.css"
//import "./css/progressSmall2.css" 
import "./css/dashboardstyle.css"
import { Link, useLocation } from 'react-router-dom';
import LoadingSpinner from "../components/LoadingSpinner";
import MainDashboardV2 from "../components/MainDashboardV2";

// import "./css/style.scss"
import DownloardPdf from './pdf/DownloardPdf';
const DMDashboardV2 = () => {
  const location = useLocation().search
  const state_id = new URLSearchParams(location).get("state_id")
  const district_id = new URLSearchParams(location).get("district_id")
  const block_id = new URLSearchParams(location).get("block_id")
  const [isLoading, setIsLoading] = useState(false);
  
  const [topSchools, setTopSchools] = useState([])
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [currentDistrict, setcurrentDistrict] = useState([]);
  const [currentBlock, setcurrentBlock] = useState([]);
  const [type, setType] = useState("district");
  const [stateList, setstateList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [currentSession, setCurrentSession] = useState('');
  const [totalDashbord, setTotalDashbord] = useState([]);
  const [formData, setformData] = useState({
    year: '2024-2025',
    state_id: state_id ? state_id : '5e3e47b700d612342102b1ad',
    stateName: "Uttarakhand",
    district_id: district_id ? district_id : '' ,
    districtName: '',
    block_id: block_id ? block_id : '',
    blockName: '',
    frequency: '',
    allState: true,
  });
  const startYear = 2023;
  let currentYear = parseInt(new Date().getFullYear());

  useEffect(() => {
    getStateList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDistrictList([]);
    getDistrictList(formData.state_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.state_id])

  useEffect(() => {
    if (formData.district_id !== '')
      getBlockList(formData.district_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.state_id, formData.district_id])

  useEffect(() => {
    if(formData.state_id && formData.district_id){
      getTotalYearDashbord(type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.year])

  useEffect(() => {
    if(formData.state_id && formData.district_id){
      getSchools()
      getTotalYearDashbord('district')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.district_id])

  useEffect(() => {
    if(formData.state_id && formData.district_id && formData.block_id){
      getTotalYearDashbord('block')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.block_id])


  const  getSchools = async ()=>{
      try {
          setIsLoading(true);
          const { data } = await axios.get(`${configKeys.SERVER_URL}/v2/topSchoolList?state_id=${formData.state_id}&district_id=${formData.district_id}&block_id=${formData.block_id}&session=${formData.year || currentSession}`);
          setTopSchools(data.data.topSchools);
          setIsLoading(false);
      } catch (error) {
          console.log(error)
          setIsLoading(false);
      }
  }

  const getStateList = async () => {
    try {
      const { data } = await axios(`${configKeys.SERVER_URL}/getStates`);
      setstateList(data.data);
      let years = []
      const today = new Date();
      let month = parseInt(today.getMonth() + 1);
      
      if (month === 0) {
        currentYear = currentYear - 1;
      }
      let option = ''
      if (month <= 3) {
        for(let i=startYear; i<currentYear; i++) {
          option = i+'-'+(i+1)
          years.push(option)        
        }
      } else {
        for(let i=startYear; i<=currentYear; i++) {
          option = i+'-'+(i+1)
          years.push(option)        
        }
      }

      setYearList(years)
      setCurrentSession(option)
      setformData({
        ...formData,
        year: option,
      });

    } catch (error) {
    }
  };

  const getDistrictList = async (stateId) => {
    try {
      const { data } = await axios(
        `${configKeys.SERVER_URL}/getDistricts?stateId=${stateId}`
      );
      setDistrictList(data.data);
      setformData({...formData,district_id:data.data[0]['_id'],districtName:data.data[0]['name']})
      setcurrentDistrict("District "+data.data[0]["name"].toLowerCase())
    } catch (error) {
    }
  };

  const getBlockList = async (districtId) => {
    try {
      const { data } = await axios(
        `${configKeys.SERVER_URL}/getBlocks?districtId=${districtId}`
      );
      setBlockList(data.data);
      setcurrentBlock("")
    } catch (error) {
    }
  };

  const getTotalYearDashbord = async (type) => {
    setIsLoading(true);
    try {
      let localData = {
        state_id: formData.state_id,
        district_id: formData.district_id,
        session: formData.year
      }
      setType(type)
      if (type === 'block') {
        localData['block_id'] = formData.block_id
      }
      /*
      districtList.forEach((item) => 
        {
          if (item["_id"] === formData.district_id) 
          {
            setcurrentDistrict("District "+item["name"].toLowerCase())
          }
        }
      )
      blockList.forEach((item) => 
        {
          if (item["_id"] === formData.block_id) 
          {
            setcurrentBlock(" - Block "+item["name"].toLowerCase())
          }
        }
      ) */
      const value = JSON.stringify(localData)
      localStorage.setItem('SDB_id',value)
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v2/totalDashbord`,localData);
      setTotalDashbord(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
	    {/* <!-- Header --> */}

        {isLoading ? '' : (
        <div className="row selections">
            <nav className="navbar navbar-expand-lg m-0 pb-2 pt-2 p-4" style={{justifyContent:'flex-end',backgroundColor:'#eeeeee'}}>
            <div className="d-flex align-items-right">
            <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav ms-auto">
    <li className='nav-item'>
    <div className={'_input_container m-2 mb-0 mt-0'}>
            <select
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="year"
              name="year"
              value={formData.year}
              onChange={(t) => {
                setformData({
                  ...formData,
                  year: t.target.value,
                });
              }}
            >
              {yearList.map((item) => (
                <option id={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
    </li>
    <li className='nav-item'>
    <div className={'_input_container m-2 mb-0 mt-0'}>
            {/* <label className={'_label'}>Select States </label> */}              
            <select
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="state"
              name="state"
              value={formData.state_id}
              onChange={(t) => {
                setformData({
                  ...formData,
                  state_id: t.target.value,
                  stateName: t.target.selectedOptions[0].text,
                });
              }}
            >
              {stateList.map((item) => (
                <option id={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
    </li>
    <li className='nav-item'>
    <div className={'_input_container m-2 mb-0 mt-0'}>
            <select
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="district"
              name="district"
              value={formData.district_id}
              onChange={(t) => {
                setformData({
                  ...formData,
                  district_id: t.target.value,
                  districtName: t.target.selectedOptions[0].text,
                  block_id:"",
                  blockName:""
                });
                setcurrentDistrict("District "+t.target.selectedOptions[0].text.toLowerCase())
              }}
            >
              {districtList.map((item) => (
                <option id={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
    </li>		
    <li className='nav-item'>

    <div className={'_input_container m-2 mb-0 mt-0'}>
            <select
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="block"
              name="block"
              defaultValue={""}
              value={formData.block_id}
              onChange={(t) => {
                setformData({
                  ...formData,
                  block_id: t.target.value,
                  blockName: t.target.selectedOptions[0].text,
                });
                if (t.target.value === '') {
                  setcurrentBlock("")
                } else {
                  setcurrentBlock(" - Block "+t.target.selectedOptions[0].text.toLowerCase())
                }
              }}
            >
              <option value="">
                All Blocks
              </option>
              {blockList?.map((item) => (
                <option id={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
    </li>
    { <li className="d-flex">
    <DownloardPdf state={formData?.state_id} district={formData?.district_id} block={formData?.block_id} session={formData?.year}  />
    </li>}
    {((formData.block_id !== '' && formData.block_id === '111111111111111') || (totalDashbord?.samparkTVData?.tv_utilization?.overall_average === 0)) ?  '' : <li className="">
                  <Link target="_blank" className="nav-block nav-link m-2 mt-0 mb-0" to={`/topBlocks?state_id=${formData.state_id}&district_id=${formData.district_id}&session=${formData.year || currentSession}`} >Top Blocks</Link>
                </li> }
    {(totalDashbord?.samparkTVData?.tv_utilization?.overall_average === 0 && formData.block_id === '111111111111111') ?  '' : <li className="">
                  <Link target="_blank"  className="nav-block nav-link m-2 mt-0 mb-0" to={`/schoolList?state_id=${formData.state_id}&district_id=${formData.district_id}&block_id=${formData.block_id}&session=${formData.year || currentSession}`} >Top Schools</Link>
                </li> }
            </ul>
            </div>
            </div>
            </nav>   
        </div>
        )}
        
        {isLoading ? <LoadingSpinner /> : <MainDashboardV2 currentDistrict={currentDistrict} currentBlock={currentBlock} stateName={formData?.stateName} totalDashbord={totalDashbord} topSchools={topSchools} selectedSession={formData.year || currentSession} />} 
    </>
  )
}

export default DMDashboardV2

