import React from "react";
import ProgressGraph from "../components/ProgressGraph";
import { Link } from 'react-router-dom';

import logo from   "./images/logo-black.svg"

const BlockDashboard = (props) => {
    const topBlocksLoading = (props.topBlocksLoading) ? props.topBlocksLoading : "";
    const topBlocks = (props.topBlocks) ? props.topBlocks : "";
    const currentDistrict = (props.currentDistrict) ? props.currentDistrict : "";
    const selectedSession = (props.selectedSession) ? props.selectedSession : "";
    const districtAverage = (props.districtAverage) ? props.districtAverage : "";
    const topSchools = (props.topSchools) ? props.topSchools : "";
    const upperPrimaryStatus = (props.upperPrimaryStatus) ? props.upperPrimaryStatus : "";
    const state_id = (props.state_id) ? props.state_id : "";
    const district_id = (props.district_id) ? props.district_id : "";
    const colorHigh = 'green'
    const colorLow = 'rgb(255, 166, 0)'
    
    
    function DateDisplay() {
        let makeDate = new Date();
        let currYear = makeDate.getFullYear();
        let currMonth = makeDate.getMonth();
        //console.log('currMonth : ',currMonth);
        let startMonth = 'April';
        let currentSession = ''
        if (currMonth === 0) {
          currYear = currYear - 1
        }
        if (currMonth === 0 || currMonth === 1 || currMonth === 2) {
          currentSession = (currYear-1)+'-'+(currYear)
        } else {
          currentSession = currYear+'-'+(currYear+1)
        }
        if (currentSession === selectedSession) {
            let startYear = currentSession.split("-")[0]
            const currDay = makeDate.getDate();
            let currMonth = ''
            if (currDay >= 30) {
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            } else {
                makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            }
            if (currMonth === startMonth) {
                return <p className="duration">{'April '+startYear}</p>;
            } else {
                return <p className="duration">{'April '+startYear+' - '+currMonth+' '+currYear}</p>;
            }
        } else {
            return <p className="duration">{'April '+selectedSession.split("-")[0]+' - March '+selectedSession.split("-")[1]}</p>;
        }
    }
    
    const topBlocksDashboard = (
        <div>
            {!topBlocksLoading.loading && topBlocks.length > 0 && (
                topBlocks.map((item,key) => (
                <>
                <div className="row divider">
                    <div className={key%2 === 0 ? 'col-md-2 left-bg-1 d-flex align-items-center justify-content-center' : 'col-md-2 left-bg-2 d-flex align-items-center justify-content-center'}>
                        <div className="side-l-t p-4">
                            <div className="side-l-t-content white ct">
                                <p className="bold">
                                <Link className="m-2 mt-0 mb-0" target="_blank" style={{color:'#ffffff'}} to={`/schoolList?state_id=${state_id}&district_id=${district_id}&block_id=${item.block_id}`} >{item.block_name}</Link>
                                <br></br></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="card-body d-flex pt-2 pb-2 mb-2 mt-2" style={{justifyContent:'space-evenly'}}>
                                        {/* <!-- <div className="ct align-items-center justify-content-center">
                                            <RatioGraph width={180} mathprogress={parseFloat(item.math_average.toFixed(2))} scienceprogress={parseFloat(item.science_average.toFixed(2))} hindiprogress={parseFloat(item.hindi_average.toFixed(2))} englishprogress={parseFloat(item.english_average.toFixed(2))}/>
                                        </div> --> */}
                                        <div className="ct align-items-center justify-content-center">
                                            <ProgressGraph width={180} progress={parseFloat(item.class15_average.toFixed(2))} color={item.class15_average >= districtAverage.class15 ? colorHigh : colorLow}/>
                                        </div>
                                        <div className="ct align-items-center justify-content-center">
                                            <ProgressGraph width={180} progress={parseFloat(item.class68_average.toFixed(2))} color={item.class68_average >= districtAverage.class68 ? colorHigh : colorLow} isNA={upperPrimaryStatus === 0 ? 'YES' : 'NO'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex mt-2" style={{justifyContent:'start'}}>
                                <p className="themecolor schoolname">
                                {topSchools[item.block_id] && topSchools[item.block_id].length > 0 && (
                                    topSchools[item.block_id].map((itemS,key) => {
                                        if (key <= 2) 
                                        return <span title={itemS.name}>
                                                *&nbsp;&nbsp;{itemS.school_name}<br></br>
                                            </span>
                                        return ''
                                    })
                                )}
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                ))
            )}
        </div>
    )
    return (
        <div className="page-break">
            <div className="row">
                <div className="col-md-6 d-flex pt-2 pb-2" style={{justifyContent:'left'}}>
                    <div className="bar-item m-4">
                        <img src={logo} alt="Logo" width={250} />
                    </div>
                </div>
                <div className="col-md-6 d-flex pt-2 pb-2" style={{justifyContent:'right'}}>
                    <div className="bar-item m-4">
                        <p className="red district">{currentDistrict}</p>
                        {DateDisplay()}
                    </div>
                </div>
            </div>
            {/* <!-- Block Section --> */}
            <div className="row">
                <div className="col-md bg-sec d-flex" style={{justifyContent:'space-evenly'}}>                
                    <div className="bar-item d-flex" style={{justifyContent:'space-evenly',alignItems:'center'}}>
                        <h1 className="m-4 bold">
                        TEACHING AS PER ACADEMIC PLAN - TOP BLOCKS
                        </h1>
                    </div>
                </div>
            </div>

            {/* <!-- Fifth Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-4 d-flex align-items-center justify-content-center">
                    <div className="side-l-t">
                        <div className="side-l-t-content white ct">
                            <p className="bold mt-4">BLOCK NAME</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10 left-bg-4 ">
                    <div className="">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="card-body d-flex pt-4 pb-4 mb-4 mt-4" style={{justifyContent:'space-evenly'}}>
                                    {/* <!-- <div className="ct white align-items-center justify-content-center" style={{width:'100px'}}>
                                        <p className="bold mt-4">SUBJECT</p>
                                    </div>  --> */}
                                    <div className="ct white align-items-center justify-content-center" style={{width:'100px'}}>
                                        <p className="bold mt-4">CLASS<br></br>1-5</p>
                                    </div>
                                    <div className="ct white align-items-center justify-content-center" style={{width:'100px'}}>
                                        <p className="bold mt-4">CLASS<br></br>6-8</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 white d-flex align-items-center" style={{justifyContent:'start'}}>
                                <p className="bold mt-4">TOP 3 SCHOOLS IN EACH BLOCK</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {topBlocksDashboard}
        </div>
    );
}

export default BlockDashboard
