import React from "react";
import ProgressGraph from "../components/ProgressGraph";
//import RatioGraph from "../components/RatioGraph";

import logo from "./images/logo-black.svg"
import star from "./images/star.png"
import estar from "./images/Estar.png"
import hstar from "./images/Hstar.png"
import iconv from "./images/iconv.png"

const MainDashboard = (props) => {
    const stateName = (props.stateName) ? props.stateName : "";
    const selectedSession = (props.selectedSession) ? props.selectedSession : "";
    const totalDashbord = (props.totalDashbord) ? props.totalDashbord : "";
    const currentDistrict = (props.currentDistrict) ? props.currentDistrict : "";
    const currentBlock = (props.currentBlock) ? props.currentBlock : "";
    
    function DateDisplay() {
        let makeDate = new Date();
        let currYear = makeDate.getFullYear();
        let currMonth = makeDate.getMonth();
        //console.log('currMonth : ',currMonth);
        let startMonth = 'April';
        let currentSession = ''
        if (currMonth === 0) {
          currYear = currYear - 1
        }
        if (currMonth === 0 || currMonth === 1 || currMonth === 2) {
          currentSession = (currYear-1)+'-'+(currYear)
        } else {
          currentSession = currYear+'-'+(currYear+1)
        }
        if (currentSession === selectedSession) {
            let startYear = currentSession.split("-")[0]
            const currDay = makeDate.getDate();
            let currMonth = ''
            if (currDay >= 30) {
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            } else {
                makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            }
            if (currMonth === startMonth) {
                return <p className="duration">{'April '+startYear}</p>;
            } else {
                return <p className="duration">{'April '+startYear+' - '+currMonth+' '+currYear}</p>;
            }
        } else {
            return <p className="duration">{'April '+selectedSession.split("-")[0]+' - March '+selectedSession.split("-")[1]}</p>;
        }
    }

    const returnStar = (length) => {
        if (length === 0) {
          return (
            <>
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if(length <= 0.5) {
            return (
              <>
                <img src={hstar} alt="hstar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 1) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 1.5) {
            return (
              <>
                 <img src={star} alt="star"  />
                 <img src={hstar} alt="hstar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 2) {
          return (
            <>
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 2.5) {
          return (
            <>
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={hstar} alt="hstar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 3) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 3.5) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={hstar} alt="hstar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 4) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 4.5) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={hstar} alt="hstar"  />
              </>
            );
          }else if (length <= 5) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
              </>
            );
          }
      };
      
    const districtDashboard = (
        <div>
            {/* <!-- Block Section --> */}
            <div className="row bg-sec">
            <div className="col-md-3 d-flex pt-2 pb-2" style={{justifyContent:'space-evenly'}}>
                <div className="bar-item m-4">
                    <h1 className="bold statename">{stateName}</h1>
                    <p className="bold statename">Since Inception</p>
                </div>
            </div>
            <div className="col-md-9 d-flex pt-2 pb-2" style={{justifyContent:'space-evenly'}}>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{totalDashbord?.inceptionData?.schoolsImpacted}</h2>
                    <p className="bold">Schools<br></br>Impacted</p>
                </div>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{totalDashbord?.inceptionData?.sssKitsDonated}</h2>
                    <p className="bold">SSS Kits<br></br>Distributed</p>
                </div>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{totalDashbord?.inceptionData?.samparkTVDonated}</h2>
                    <p className="bold">Sampark TVs<br></br>Distributed</p>
                </div>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{totalDashbord?.inceptionData?.teachersTrained}</h2>
                    <p className="bold">Teachers<br></br>Trained</p>
                </div>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{totalDashbord?.activeYears}</h2>
                    <p className="bold">Active<br></br>Years</p>
                </div>
            </div>
        </div>

            {/* <!-- First Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-1 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">1. TEACHERS<br />FEEDBACK</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card-body p-4 mb-4">
                                    <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <div className="rating-card-start">
                                                <div className="rating-text">
                                                    <div className="rating-heading">
                                                        Rating
                                                    </div>
                                                    <div className="rating-number">
                                                    {`${parseFloat(totalDashbord?.teacherFeedback?.overall_rating?.toFixed(2))}`}
                                                    </div>                        
                                                </div>
                                                <div className="ratings">
                                                {returnStar((totalDashbord?.teacherFeedback?.overall_rating))}
                                                    {/* <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star"></i> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 rightsection">
                                <div className="card-body bl p-4">
                                    <div className="card-heading m-2 mt-0">
                                        {totalDashbord?.topBlocks?.teacherFeedback.length > 0 ? 'TOP BLOCKS' : (totalDashbord?.topSchools?.teacherFeedback.length > 0 ? 'TOP SCHOOLS' : '') }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 align-items-center justify-content-center">
                                            <p className="topblocks themecolor">
                                            {totalDashbord?.topBlocks?.teacherFeedback.map((item,index) => 
                                            {
                                                if (index <= 2 && item.rating > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${parseFloat(item.rating?.toFixed(2))}`}/5<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            {totalDashbord?.topSchools?.teacherFeedback.map((item,index) => 
                                            {
                                                if (index <= 2 && item.rating > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${parseFloat(item.rating?.toFixed(2))}`}/5<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            </p>
                                        </div>
                                        <div className="col-md-6 align-items-center justify-content-center">
                                            <p className="topblocks themecolor">
                                            {totalDashbord?.topBlocks?.teacherFeedback.map((item,index) => 
                                            {
                                                if (index > 2 && index <= 5 && item.rating > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${parseFloat(item.rating?.toFixed(2))}`}/5<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            {totalDashbord?.topSchools?.teacherFeedback.map((item,index) => 
                                            {
                                                if (index > 2 && index <= 5 && item.rating > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${parseFloat(item.rating?.toFixed(2))}`}/5<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                        <p className="m-0">SMS Feedback by <b>{`${parseFloat(totalDashbord?.teacherFeedback?.overall_feedback?.toFixed(2))}`}</b> teachers</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Second Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-2 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">2. SCHOOLS WITH TRAINED TEACHERS</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card-body mb-4 mt-4">
                                    <div className="row">
                                        <div className="col-md-6 ct align-items-center justify-content-center">
                                            <ProgressGraph progress={totalDashbord?.schoolsImpacted > 0 ? parseFloat((totalDashbord?.totalTrainedSchools/totalDashbord?.schoolsImpacted*100)?.toFixed(2)) : 0}/>
                                            <p className="m-0 bold themecolor">
                                            <img src={iconv} alt="iconv"  />
                                            </p>
                                            <p className="m-0 bold themecolor small">Till Date</p>
                                            <p className="m-0 bold small">{totalDashbord?.totalTrainedSchools}/{totalDashbord?.schoolsImpacted}</p>
                                        </div>
                                        <div className="col-md-6 ct align-items-center bl justify-content-center">
                                            <ProgressGraph progress={totalDashbord?.schoolsImpacted > 0 ? parseFloat((totalDashbord?.trainedSchools/totalDashbord?.schoolsImpacted*100)?.toFixed(2)) : 0}/>
                                            <p className="m-0 bold themecolor">
                                            <img src={iconv} alt="iconv"  />
                                            </p>
                                            <p className="m-0 bold themecolor small">This Year</p>
                                            <p className="m-0 bold small">{totalDashbord?.trainedSchools}/{totalDashbord?.schoolsImpacted}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 rightsection">
                                <div className="card-body bl p-4">
                                    <div className="card-heading m-2 mt-0">
                                    {totalDashbord?.topBlocks?.trainedSchools.length > 0 ? 'TOP BLOCKS' : (totalDashbord?.topSchools?.trainedSchools.length > 0 ? 'TOP SCHOOLS' : '') }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 align-items-center justify-content-center">
                                            <p className="topblocks themecolor">
                                            {totalDashbord?.topBlocks?.trainedSchools.map((item,index) => 
                                            {
                                                if (index <= 5 && item.percent > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${(item.percent > 100) ? 100 : parseFloat(item.percent?.toFixed(2))}`}%<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            {totalDashbord?.topSchools?.trainedSchools.map((item,index) => 
                                                {
                                                    if (index <= 5 && item.total > 0) 
                                                    return <span title={item.name}>
                                                            {item.name} : {`${parseFloat(item.total?.toFixed(2))}`}<br></br>
                                                        </span>
                                                    return ''
                                                }
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                            <p className="m-0">Schools have trained teachers</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- THIRD Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-3 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">3. TEACHING AS PER ACADEMIC PLAN - % OF SCHOOLS</p>
                        </div>

                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <div className="row mb-2 mt-4">
                                        <div className="col-md-6 ct align-items-center br justify-content-center">
                                            <ProgressGraph progress={parseFloat(totalDashbord?.samparkTVData?.tv_utilization?.class15_average?.toFixed(2))}/>
                                            <p className="m-0 bold themecolor small">Class 1-5</p>
                                        </div>
                                        <div className="col-md-6 ct align-items-center justify-content-center">
                                            <ProgressGraph progress={parseFloat(totalDashbord?.samparkTVData?.tv_utilization?.class68_average?.toFixed(2))} isNA={totalDashbord?.upperPrimarySTVSchools === 0 ? 'YES' : 'NO'}/>
                                            <p className="m-0 bold themecolor small">Class 6-8</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 ct align-items-center justify-content-center">
                                            <p className="m-0 bold small">% of Schools with &gt; 5 Lesson complete</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                            <p className="m-0">Sampark TV usage data uploaded by teachers & physical visits to schools
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Fourth Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-4 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">4. SMART CLASS (SAMPARK TV) ACTIVE USER SCHOOLS</p>
                        </div>

                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card-body mb-4 mt-4">
                                    <div className="row">
                                        <div className="col-md-6 ct align-items-center justify-content-center">
                                            <ProgressGraph progress={totalDashbord?.tvDistributed > 0 ? parseFloat((totalDashbord?.samparkTVData?.total_installed/totalDashbord?.tvDistributed*100)?.toFixed(2)) : 0}/>
                                            <p className="m-0 bold themecolor">
                                            <img src={iconv} alt="iconv"  />
                                            </p>
                                            <p className="m-0 bold themecolor small">% Installed</p>
                                            <p className="m-0 bold small">{totalDashbord?.samparkTVData?.total_installed}/{totalDashbord?.tvDistributed} Schools</p>
                                        </div>
                                        <div className="col-md-6 ct align-items-center bl justify-content-center">
                                            <ProgressGraph progress={totalDashbord?.tvDistributed > 0 ? parseFloat((totalDashbord?.samparkTVData?.synced_monthly/totalDashbord?.tvDistributed*100)?.toFixed(2)) : 0}/>
                                            <p className="m-0 bold themecolor">
                                            <img src={iconv} alt="iconv"  />
                                            </p>
                                            <p className="m-0 bold themecolor small">% Synced Monthly</p>
                                            <p className="m-0 bold small">{totalDashbord?.samparkTVData?.synced_monthly}/{totalDashbord?.tvDistributed} Schools</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 rightsection">
                                <div className="card-body bl p-4">
                                    <div className="card-heading m-2 mt-0">
                                    {totalDashbord?.topBlocks?.synced_monthly.length > 0 ? 'TOP BLOCKS' : (totalDashbord?.topSchools?.synced_monthly.length > 0 ? 'TOP SCHOOLS' : '') }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 align-items-center justify-content-center">
                                            <p className="topblocks themecolor">
                                            {totalDashbord?.topBlocks?.synced_monthly.map((item,index) => 
                                            {
                                                if (index <= 5 && item.percent > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${(item.percent > 100) ? 100 : parseFloat(item.percent?.toFixed(2))}`}%<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            {totalDashbord?.topSchools?.synced_monthly.map((item,index) => 
                                            {
                                                if (index <= 5) 
                                                return <span title={item.school_name}>
                                                        {item.school_name} : {`${parseFloat(item.overall_completed_lesson?.toFixed(2))}`}<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                            <p className="m-0">Sampark TV usage data uploaded weekly by teachers
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FIFTH Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-5 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">5. TEACHERS REVIEW LESSONS BEFORE TEACHING IN CLASS</p>
                        </div>

                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-center  align-items-center">
                                            <ProgressGraph progress={totalDashbord?.schoolsImpacted > 0 ? parseFloat((totalDashbord?.learnToTeach?.schools/totalDashbord?.schoolsImpacted*100)?.toFixed(2)) : 0}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 rightsection">
                                <div className="card-body bl p-4">
                                    <div className="card-heading m-2 mt-0">
                                    {totalDashbord?.topBlocks?.learnToTeachs.length > 0 ? 'TOP BLOCKS' : (totalDashbord?.topSchools?.learnToTeachs.length > 0 ? 'TOP SCHOOLS' : '') }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 align-items-center justify-content-center">
                                            <p className="topblocks themecolor">
                                            {totalDashbord?.topBlocks?.learnToTeachs.map((item,index) => 
                                            {
                                                if (index <= 2 && item.percent > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${(item.percent > 100) ? 100 : parseFloat(item.percent?.toFixed(2))}`}%<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            {totalDashbord?.topSchools?.learnToTeachs.map((item,index) => 
                                            {
                                                if (index <= 2 && item.total > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${parseFloat(item.total?.toFixed(2))}`}<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            </p>
                                        </div>
                                        <div className="col-md-6 align-items-center justify-content-center">
                                            <p className="topblocks themecolor">
                                            {totalDashbord?.topBlocks?.learnToTeachs.map((item,index) => 
                                            {
                                                if (index > 2 && index <= 5 && item.percent > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${(item.percent > 100) ? 100 : parseFloat(item.percent?.toFixed(2))}`}%<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            {totalDashbord?.topSchools?.learnToTeachs.map((item,index) => 
                                            {
                                                if (index > 2 && index <= 5 && item.total > 0) 
                                                return <span title={item.name}>
                                                        {item.name} : {`${parseFloat(item.total?.toFixed(2))}`}<br></br>
                                                    </span>
                                                return ''
                                            }
                                            )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                            <p className="m-0">{totalDashbord?.learnToTeach?.schools}/{totalDashbord?.schoolsImpacted} Schools follow "Sahi Kram Sahi Dhang"</p>
                        </div>
                    </div>
                </div>
            </div>            
        
        </div>
    )
    return (
        <div className="printme">
            <div className="row">
                <div className="col-md-6 d-flex pt-2 pb-2" style={{justifyContent:'left'}}>
                    <div className="bar-item m-4">
                        <img src={logo} alt="Logo" width={250} />
                    </div>
                </div>
                <div className="col-md-6 d-flex pt-2 pb-2" style={{justifyContent:'right'}}>
                    <div className="bar-item m-4">
                        <p className="red district">{currentDistrict} {currentBlock}</p>
                        {DateDisplay()}
                    </div>
                </div>
            </div>
            {districtDashboard}
        </div>
    );
}

export default MainDashboard
